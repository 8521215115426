import { makeStyles } from '@fluentui/react';

export const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexDirection: 'row',
      paddingLeft: 20,
    },
    groupSelected: {
      backgroundColor: theme.palette.neutralQuaternaryAlt,
    },
    linkLightHover: {
      ':hover': {
        backgroundColor: theme.palette.neutralQuaternaryAlt,
      },
    },
    redColor: {
      color: 'red',
    },
    listCol1: {
      paddingLeft: '18px',
      flex: 0.2,
    },
    hideCollapseButton: {
      width: 30,
    },
    fullWidthContainer: {
      width: '100%',
    },
    deleteIconColor: {
      color: 'red',
    },
    removeIconContainer: {
      alignItems: 'center',
      width: 30,
      justifyContent: 'center',
      marginRight: 15,
      marginLeft: 10,
    },

    marginL5: {
      marginLeft: 10,
      alignItems: 'center',
    },
    renderAccountingOptions: {
      paddingTop: 20,
      paddingBottom: 10,
      backgroundColor: theme.palette.neutralLighter,
      paddingRight: 40,
    },
    container: {
      backgroundColor: theme.palette.neutralLighter,
    },
    accountingRowMargin: {
      // marginTop: '5px !important',
    },
    marginLeft20: {
      marginLeft: 20,
    },
    marginLeft5: {
      marginLeft: 5,
    },

    paddingLeft10: {
      paddingLeft: 10,
    },
    fixedWidth: {
      width: 30,
      marginLeft: 5,
      textAlign: 'center',
    },
    minWidth150: {
      minWidth: 150,
      marginLeft: 5,
      textAlign: 'center',
    },
    minWidth180: {
      minWidth: 180,
      marginLeft: 10,
      textAlign: 'center',
    },
    formikCheckboxStyle: {
      width: 150,
      marginLeft: 20,
      alignItems: 'center',
    },
  };
});
