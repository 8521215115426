import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';

const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const colHeaderLeftAlignStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    marginLeft: 0,
  },
};
const useColumns = () => {
  const { isSm, isLg } = useGetScreenWidth();
 
  const columns: ColumnData[] = [
    {
      key: '_urgencyLevel',
      name: '',
      showColumnTitle:'Urgent',
      fieldName: '_urgencyLevel',
      minWidth: 10,
      maxWidth: 10,
      styles: colHeaderLeftAlignStyle,
      columnActionsMode: ColumnActionsMode.disabled,
      ...commonColumnProps,
    },
    {
      key: 'purchaseOrderNumber',
      name: 'PO Number',
      fieldName: 'purchaseOrderNumber',
      minWidth: 130,
      ...commonColumnProps,
      isSorted: true,
      isSortedDescending: true,
    },
    {
      key: 'purchaseOrderDate',
      name: 'Date',
      fieldName: 'purchaseOrderDate',
      minWidth: 100,
      ...commonColumnProps,
    },
    {
      key: 'sortSupplierName',
      name: 'Supplier',
      fieldName: 'sortSupplierName',
      minWidth: 200,
      ...commonColumnProps,
    },
    {
      key: 'description',
      name: 'Description',
      fieldName: 'description',
      minWidth: 250,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: 'department',
      name: 'Department',
      fieldName: 'department',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'businessUnit',
      name: 'Business Unit',
      fieldName: 'businessUnit',
      minWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'purchaseOrderType',
      name: 'Type',
      fieldName: 'purchaseOrderType',
      minWidth: 150,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'currency',
      name: 'Currency',
      fieldName: 'currency',
      minWidth: 80,
      maxWidth: 80,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'controlTotalAmount',
      name: 'Total',
      fieldName: 'controlTotalAmount',
      minWidth: 60,
      maxWidth: 120,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
    },
    {
      key: 'appliedUsedTotals',
      name: 'Used',
      fieldName: 'appliedUsedTotals',
      minWidth: 80,
      maxWidth: 80,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'appliedRemainingAmount',
      name: 'Remaining',
      fieldName: 'appliedRemainingAmount',
      minWidth: 100,
      maxWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'appliedAmount',
      name: 'Applied',
      fieldName: 'appliedAmount',
      minWidth: 80,
      maxWidth: 80,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'appliedRetiredAmount',
      name: 'Retired',
      fieldName: 'appliedRetiredAmount',
      minWidth: 80,
      maxWidth: 80,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'appliedOverageAmount',
      name: 'Overage',
      fieldName: 'appliedOverageAmount',
      minWidth: 80,
      maxWidth: 80,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_referenceItemTypes',
      name: 'Item Types',
      fieldName: '_referenceItemTypes',
      minWidth: 150,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'transactionStamp',
      name: 'Accounting Trans #',
      fieldName: 'transactionStamp',
      minWidth: 130,
      ...commonColumnProps,
      isVisible: false,
      toggleVisibility: false,
    },
    {
      key: '_accountingStampTransactionReference',
      name: 'Transaction #',
      fieldName: '_accountingStampTransactionReference',
      minWidth: 130,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_createdByUserName',
      name: 'Created By',
      fieldName: '_createdByUserName',
      minWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_createdDate',
      name: 'Created On',
      fieldName: '_createdDate',
      minWidth: 100,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_baseCurrencyAmount',
      name: 'USD Amount',
      fieldName: '_baseCurrencyAmount',
      minWidth: 100,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: '_spotCurrencyAmount',
      name: 'USD Spot Rate',
      fieldName: '_spotCurrencyAmount',
      minWidth: 110,
      styles: rightAlignHeaderStyle,
      ...commonColumnProps,
      isVisible: false,
    },
    {
      key: 'status',
      name: 'Status',
      fieldName: 'status',
      minWidth: 160,
      ...commonColumnProps,
    },
  ];
  return { columns };
};
export { useColumns };
