import { IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
import useGetScreenWidth from 'common/hooks/useGetScreenWidth';
const rightAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'flex-end',
  },
};

const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};
const useColumns = () => {
  const { isSm, isMd, isLg } = useGetScreenWidth();

  const columns: ColumnData[] = [
    {
      key: 'documentType',
      name: 'Type',
      fieldName: 'documentType',
      minWidth: 110,
      maxWidth: 110,
      ...commonColumnProps,
    },

    {
      key: 'indexName',
      name: 'Name',
      fieldName: 'indexName',
      minWidth: 155,
      maxWidth: 155,
      ...commonColumnProps,
    },
    {
      key: 'indexReferenceNumber',
      name: 'Number',
      fieldName: 'indexReferenceNumber',
      minWidth: 100,
      maxWidth: 100,
      ...commonColumnProps,
      isVisible: !isSm,
    },
    {
      key: 'indexTransactionDate',
      name: 'Date',
      fieldName: 'indexTransactionDate',
      minWidth: 60,
      maxWidth: 60,
      ...commonColumnProps,
      isVisible: !isSm,
    },

    {
      key: '_documentContents',
      name: 'Contents',
      fieldName: '_documentContents',
      minWidth: 120,
      maxWidth: 120,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'indexAmount',
      name: 'Total',
      fieldName: 'indexAmount',
      minWidth: 70,
      maxWidth: 70,
      ...commonColumnProps,
      isVisible: !isSm,
      styles: rightAlignHeaderStyle,
    },

    {
      key: 'usedTotal',
      name: 'Used',
      fieldName: 'usedTotal',
      minWidth: 70,
      maxWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
      styles: rightAlignHeaderStyle,
    },
    {
      key: 'remainingTotal',
      name: 'Remaining',
      fieldName: 'remainingTotal',
      minWidth: 70,
      maxWidth: 70,
      ...commonColumnProps,
      isVisible: isLg,
      styles: rightAlignHeaderStyle,
    },

    {
      key: 'isoCode',
      name: 'Currency',
      fieldName: 'isoCode',
      minWidth: 60,
      maxWidth: 60,
      ...commonColumnProps,
      isVisible: isLg,
    },

    {
      key: 'comment',
      name: 'Comments',
      fieldName: 'comment',
      minWidth: 80,
      maxWidth: 80,
      isResizable: true,
      ...commonColumnProps,
      isVisible: isLg,
    },
    {
      key: 'view',
      name: '',
      fieldName: '',
      showColumnTitle: 'View/Download',
      minWidth: 20,
      maxWidth: 20,
      ...commonColumnProps,
    },
    {
      key: 'download',
      name: '',
      fieldName: '',
      minWidth: 20,
      maxWidth: 20,
      ...commonColumnProps,
      toggleVisibility: false,
    },
    {
      key: 'fileReference',
      name: 'Documents',
      fieldName: 'fileReference',
      minWidth: 110,
      maxWidth: 110,
      isResizable: true,
      isVisible: !isMd,
    },
    {
      key: '_uploadDate',
      name: 'Uploaded',
      fieldName: '_uploadDate',
      minWidth: 150,
      maxWidth: 150,
      ...commonColumnProps,
      styles: rightAlignHeaderStyle,
    },
  ];
  return { columns };
};
export { useColumns };
