import {
  Icon,
  Link,
  Stack,
  AnimationClassNames,
  Text,
  IconButton,
} from '@fluentui/react';
import clsx from 'clsx';
import { FormikCheckBox, FormikNumberTextField } from 'common/components';
import { FormikTextField } from 'common/components/FormikFields/TextField';
import { useCommonStyles } from 'common/styles';
import { FieldArray, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { ApprovalSetupCommonData } from 'settings/account/approvalSetup/__generated__/ApprovalSetupCommonData';
import { APPROVAL_BRANCH_INITIAL_VALUES } from '../../constants';
import { ApprovalLevelViewRowValues } from '../../types';
import { ApproverDataItem } from '../ApproverDataItem';
import { useStyles } from './index.styles';

interface ApprovalDataItemProps {
  data: ApprovalLevelViewRowValues;
  approvalLevelsName: string;
  distributionTypes: ApprovalSetupCommonData;
  index: number;
  lastRow: number;
  isSelected: boolean;
  isDisabled?: boolean;
  onItemClick?: () => void;
  selectedTransaction: string | number | undefined;
  onTransactionClick?: (index: number) => void;
  insertAdditionalRow?: () => void;
  deleteCurrentRow: (index: number) => void;
  deleteBranchRow: (id: string, _rowTimestamp: string | null) => void;
}

const accountingInputs = {
  approvalLevel: { width: 70, title: 'Levels' },
  name: { width: 120, title: 'Name' },
  description: { width: 180, title: 'Comment' },
  requiredBranchApprovalCount: { width: 140, title: 'Required Approvals' },
  isApprovedByConsensus: { width: 160, title: 'Approved by Consensus?' },
  isRejectOnUnderApprovalCount: {
    width: 160,
    title: 'Require Minimum Approver?',
  },
  isUsedForAdditionalDepartments: {
    width: 160,
    title: 'Additional Dept Level?',
  },
};

export const ApprovalDataItem: React.FC<ApprovalDataItemProps> = ({
  data,
  approvalLevelsName,
  distributionTypes,
  index,
  isSelected,
  onItemClick,
  insertAdditionalRow,
  lastRow,
  isDisabled,
  deleteCurrentRow,
  deleteBranchRow,
}) => {
  const styles = useStyles();
  const SlideLeftIn = AnimationClassNames.slideLeftIn400;
  const SlideRightIn = AnimationClassNames.slideLeftOut400;
  const commonStyles = useCommonStyles();
  const { setFieldValue } = useFormikContext<ApprovalLevelViewRowValues>();
  const [showRemoveIcon, setShowRemoveIcon] = useState<boolean>(false);
  const handleDataUpdate = () => {
    const edited = Object.values(data).filter(
      (field) => field !== null && field !== ''
    );
    if (edited.length > 1 && lastRow === index && !isDisabled) {
      if (data.approvalLevel && data.name && data.requiredBranchApprovalCount) {
        onItemClick?.();
        insertAdditionalRow?.();
      }
    }
  };

  const handleDataUpdateMemo = useCallback(handleDataUpdate, [data]);
  const removeCurrentIndex = () => {
    deleteCurrentRow(index);
  };

  useEffect(() => {
    handleDataUpdateMemo();
  }, [handleDataUpdateMemo]);

  const handleDeleteRow = () => {
    const edited = Object.values(data).filter(
      (field) => field !== null && field !== ''
    );
    if (edited.length > 1) {
      setShowRemoveIcon(true);
    } else {
      setShowRemoveIcon(false);
    }
  };

  const handleDeleteRowMemo = useCallback(handleDeleteRow, [data]);

  useEffect(() => {
    handleDeleteRowMemo();
  }, [handleDeleteRowMemo]);

  const isLinkDisable =
    !data.approvalLevel ||
    !data.name ||
    !(data.id && data.requiredBranchApprovalCount !== null);

  return (
    <>
      <Stack horizontal className={styles.marginL5}>
        <Stack className={styles.removeIconContainer}>
          {showRemoveIcon && !isDisabled ? (
            <IconButton
              onClick={removeCurrentIndex}
              iconProps={{ iconName: 'Blocked2Solid' }}
              ariaLabel="delete"
              className={styles.deleteIconColor}
            />
          ) : (
            <Stack className={styles.hideCollapseButton} />
          )}
        </Stack>
        <Link disabled={isLinkDisable} onClick={onItemClick}>
          {data?.approvalBranches?.length !== 0 && (
            <CollapseButton isOpen={isSelected} />
          )}
        </Link>
        <Stack
          tokens={{ padding: '0px 30px 0px 20px' }}
          className={styles.fullWidthContainer}
          horizontal
        >
          <Stack>
            <FormikNumberTextField
              className={styles.marginL5}
              name={`${approvalLevelsName}.approvalLevel`}
              style={{
                width: accountingInputs['approvalLevel'].width,
              }}
              underlined
              disabled={isDisabled}
            />
          </Stack>
          <Stack>
            <FormikTextField
              className={styles.marginL5}
              name={`${approvalLevelsName}.name`}
              style={{ width: accountingInputs['name'].width }}
              underlined
              disabled={isDisabled}
            />
          </Stack>
          <Stack>
            <FormikTextField
              className={styles.marginL5}
              name={`${approvalLevelsName}.description`}
              style={{ width: accountingInputs['description'].width }}
              underlined
              disabled={isDisabled}
            />
          </Stack>
          <Stack>
            <FormikNumberTextField
              className={styles.marginL5}
              name={`${approvalLevelsName}.requiredBranchApprovalCount`}
              style={{
                width: accountingInputs['requiredBranchApprovalCount'].width,
              }}
              underlined
              disabled={isDisabled}
              allowZeroValue
            />
          </Stack>
          <Stack className={styles.marginLeft20}>
            <Stack className={styles.formikCheckboxStyle}>
              <FormikCheckBox
                label=""
                boxSide="end"
                name={`${approvalLevelsName}.isApprovedByConsensus`}
                onChange={(_, value) => {
                  setFieldValue(
                    `${approvalLevelsName}.isApprovedByConsensus`,
                    value
                  );
                }}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
          <Stack className={styles.marginLeft5}>
            <Stack className={styles.formikCheckboxStyle}>
              <FormikCheckBox
                label=""
                boxSide="end"
                name={`${approvalLevelsName}.isRejectOnUnderApprovalCount`}
                onChange={(_, value) => {
                  setFieldValue(
                    `${approvalLevelsName}.isRejectOnUnderApprovalCount`,
                    value
                  );
                }}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
          <Stack className={styles.marginLeft5}>
            <Stack className={styles.formikCheckboxStyle}>
              <FormikCheckBox
                label=""
                boxSide="end"
                name={`${approvalLevelsName}.isUsedForAdditionalDepartments`}
                onChange={(_, value) => {
                  setFieldValue(
                    `${approvalLevelsName}.isUsedForAdditionalDepartments`,
                    value
                  );
                }}
                disabled={isDisabled}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <Stack className={isSelected ? SlideLeftIn : SlideRightIn}>
        {isSelected && (
          <Stack className={styles.container}>
            <Stack horizontal>
              <Stack
                tokens={{ padding: '0px 0px 0px 80px' }}
                className={styles.renderAccountingOptions}
                horizontal
                horizontalAlign="space-between"
              >
                <Stack
                  style={{ marginLeft: 25 }}
                  className={clsx(styles.minWidth150, styles.paddingLeft10)}
                >
                  <Text className={commonStyles.bold}>Order</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Comment</Text>
                </Stack>
                <Stack className={styles.minWidth180}>
                  <Text className={commonStyles.bold}>Occupation</Text>
                </Stack>
                <Stack className={styles.minWidth180}>
                  <Text className={commonStyles.bold}>Rank</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Business Unit?</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>User Group?</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Department?</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Project?</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Budget?</Text>
                </Stack>

                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Unused $</Text>
                </Stack>
                <Stack className={styles.fixedWidth}>
                  <Text className={commonStyles.bold}>(or)</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>% Used</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>From Amount</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>To Amount</Text>
                </Stack>
                <Stack className={styles.minWidth180}>
                  <Text className={commonStyles.bold}>Conversion</Text>
                </Stack>
                <Stack className={styles.minWidth150}>
                  <Text className={commonStyles.bold}>Business Unit</Text>
                </Stack>
                <Stack className={styles.minWidth180}>
                  <Text className={commonStyles.bold}>Department</Text>
                </Stack>
                <Stack className={styles.minWidth180}>
                  <Text className={commonStyles.bold}>Department Group</Text>
                </Stack>
                <Stack className={styles.minWidth180} grow>
                  <Text className={commonStyles.bold}>Not?</Text>
                </Stack>
              </Stack>
            </Stack>

            <FieldArray name={`approvalTrees[${index}].approvalBranches`}>
              {({ remove, insert }) => (
                <>
                  {data.approvalBranches?.map((item, i) => {
                    const approvalLevelsName = `approvalTrees[${index}].approvalBranches[${i}]`;
                    return (
                      <Stack
                        horizontal
                        key={i}
                        className={clsx(
                          index !== 0 && styles.accountingRowMargin
                        )}
                      >
                        <ApproverDataItem
                          deleteCurrentRow={(removeIndex: number) => {
                            remove(removeIndex);
                            if (item.id) {
                              deleteBranchRow?.(item.id, item._rowTimestamp);
                            }
                          }}
                          insertAdditionalRow={() => {
                            insert(
                              data.approvalBranches?.length!,
                              APPROVAL_BRANCH_INITIAL_VALUES
                            );
                          }}
                          lastRow={data.approvalBranches?.length! - 1}
                          rowIndex={index}
                          index={i}
                          distributionTypes={distributionTypes!}
                          data={item!}
                          approvalLevelsName={approvalLevelsName}
                          key={i.toString()}
                          isDisabled={isDisabled!}
                        />
                      </Stack>
                    );
                  })}
                </>
              )}
            </FieldArray>
          </Stack>
        )}
      </Stack>
    </>
  );
};

interface CollapseButtonProps {
  isOpen: boolean;
}

export const CollapseButton: React.FC<CollapseButtonProps> = ({
  isOpen = false,
}) => {
  return (
    <Icon
      iconName={isOpen ? 'ChevronDown' : 'ChevronRight'}
      ariaLabel="ExpandButton"
    />
  );
};
