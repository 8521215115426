import {
  NetworkStatus,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ActionButton,
  DirectionalHint,
  FontIcon,
  IDetailsRowProps,
  IRenderFunction,
  IconButton,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { IColumn } from '@fluentui/react/lib/DetailsList';
import { loader } from 'graphql.macro';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  AvailableInvoiceDocumentTypes,
  AvailableInvoiceDocumentTypesVariables,
} from '../../../__generated__/AvailableInvoiceDocumentTypes';

import { InvoiceDetails_invoice } from 'ap/signing/transactionSigning/view/__generated__/InvoiceDetails';
import { TransactionSigningValues } from 'ap/signing/transactionSigning/view/types';
import clsx from 'clsx';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { AttachDocumentModal } from 'common/components/AttachDocumentModal';
import { DocumentDataCallout } from 'common/components/AttachDocumentModal/DocumentDataCallout';
import { DocumentMetaForm } from 'common/components/AttachDocumentModal/DocumentMetaForm';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { TABLE_ROWS } from 'common/constants';
import { EntityDocumentsFields } from 'common/graphql/__generated__/EntityDocumentsFields';
import {
  ApplyInvoiceSigningDocumentInput,
  EntityDocumentFilter,
  EntityDocumentInput,
  EntityDocumentsOrderBy,
} from 'common/types/globalTypes';
import { SortOrder } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { Decimal } from 'decimal.js';
import { useFormContext, useWatch } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
  AppliableInvoiceSigningDocuments,
  AppliableInvoiceSigningDocumentsVariables,
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes,
} from './__generated__/AppliableInvoiceSigningDocuments';
import {
  ApplyInvoiceSigningDocument,
  ApplyInvoiceSigningDocumentVariables,
} from './__generated__/ApplyInvoiceSigningDocument';
import { columns } from './column.data';
import { useStyles } from './index.styles';
import { toOrderByVariable } from './utils';

const APPLIABLE_INVOICE_SIGNING_DOCUMENTS = loader(
  './AppliableInvoiceSigningDocuments.graphql'
);
const APPLY_INVOICE_SIGNING_DOCUMENTS = loader(
  './ApplyInvoiceSigningDocument.graphql'
);
const AVAILABLE_DOCUMENT_TYPES = loader(
  '../../../AvailableInvoiceDocumentTypes.graphql'
);

const PROHIBITED_MESSAGE =
  'Upload prohibited during/after the approval process';

type AttachableDataType =
  AppliableInvoiceSigningDocuments_appliableInvoiceSigningDocuments_nodes & {
    poInvoiceSchedules?: string[];
    available: string | null;
    isoCode: string | null;
  };
interface AttachFormProps {
  invoice: InvoiceDetails_invoice;
  onAttachSuccess: () => void;
}

export const AttachForm: React.FC<AttachFormProps> = ({
  invoice,
  onAttachSuccess,
}) => {
  const [dialogVisible, setDialogVisible] = useState(false);
  const {
    formState: { isDirty },
  } = useFormContext<TransactionSigningValues>();

  return (
    <>
      <Stack horizontal styles={{ root: { height: 44 } }}>
        <TooltipHost content="Attach new files">
          <ActionButton
            onClick={() => setDialogVisible(true)}
            iconProps={{ iconName: 'Attach' }}
            text="Attach"
            checked
            disabled={isDirty}
          />
        </TooltipHost>
      </Stack>
      {dialogVisible && (
        <AttachFormModal
          invoice={invoice}
          setOpen={setDialogVisible}
          onAttachSuccess={onAttachSuccess}
        />
      )}
    </>
  );
};

const AttachFormModal: React.FC<
  AttachFormProps & {
    setOpen: (open: boolean) => void;
    onAttachSuccess: () => void;
  }
> = ({ invoice, setOpen, onAttachSuccess }) => {
  const styles = useStyles();
  const [selected, setSelected] = useState<AttachableDataType[]>([]);
  const [checkApproveOverage, setCheckApproveOverage] = useState(false);
  const [checkRetireAmount, setCheckRetireAmount] = useState(false);
  const [documentAppliedAmount, setDocumentAppliedAmount] = useState(0);
  const documentMetaData = useRef(new Map<string, DocumentMetaForm>());
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });

  const { control } = useFormContext<TransactionSigningValues>();

  const watchTransactionTypeId = useWatch({
    control,
    name: 'transactionTypeId',
  });

  const vendorReference = useWatch({
    control,
    name: 'vendorReference',
  });

  const [getAvailableDocumentTypes, { data: availableDocumentTypes }] =
    useLazyQuery<
      AvailableInvoiceDocumentTypes,
      AvailableInvoiceDocumentTypesVariables
    >(AVAILABLE_DOCUMENT_TYPES, {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    if (watchTransactionTypeId)
      getAvailableDocumentTypes({
        variables: {
          transactionTypeId: watchTransactionTypeId!,
          isDocumentUpload: false,
        },
      });
  }, [watchTransactionTypeId, getAvailableDocumentTypes]);

  const canSelectItem = useCallback(
    (item: AttachableDataType) =>
      invoice.isDraft ? true : !item._isAccountingDocument,
    [invoice]
  );

  const {
    data: documentsList,
    loading: documentsLoading,
    fetchMore,
    variables: documentsVariables,
    networkStatus,
    refetch,
  } = useQuery<
    AppliableInvoiceSigningDocuments,
    AppliableInvoiceSigningDocumentsVariables
  >(APPLIABLE_INVOICE_SIGNING_DOCUMENTS, {
    variables: {
      first: TABLE_ROWS,
      invoiceId: invoice.id,
      cardHolderId: invoice.cardHolderId,
      orderBy: [
        EntityDocumentsOrderBy._UPLOAD_DATE_DESC,
        EntityDocumentsOrderBy.PRIMARY_KEY_DESC,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const [attachDocuments, { loading: attachLoading }] = useMutation<
    ApplyInvoiceSigningDocument,
    ApplyInvoiceSigningDocumentVariables
  >(APPLY_INVOICE_SIGNING_DOCUMENTS, { errorPolicy: 'all' });

  const { addToast } = useToasts();

  const getDocumentAppliedAmount = useCallback(
    () =>
      Array.from(documentMetaData.current.values()).reduce(
        (curr, next) => curr + (Number(next.documentAppliedAmount) || 0),
        0
      ),
    []
  );

  const onSelectionChanged = useCallback(
    (items: AttachableDataType[]) => {
      setSelected(items);

      //add new selections to meta data map
      items.forEach((item) => {
        if (!documentMetaData.current.has(item.id))
          documentMetaData.current.set(item.id, {
            documentAppliedAmount: item._isDocumentAmountAvailable
              ? item.documentAppliedAmounts?.remainingTotal!
              : undefined,
          });
      });

      //remove old selections from meta data map
      Array.from(documentMetaData.current.keys()).forEach((id) => {
        if (!items.some((item) => item.id === id))
          documentMetaData.current.delete(id);
      });

      setDocumentAppliedAmount(getDocumentAppliedAmount());
    },
    [getDocumentAppliedAmount]
  );

  const documentTypeOptions =
    availableDocumentTypes?.invoiceSigningAvailableDocumentTypes?.nodes.map(
      (doctype) => ({
        key: doctype.id,
        text: doctype.documentType || '',
        title:
          (!invoice?.isDraft &&
            doctype.isAccountingDocument &&
            'Upload prohibited during/after the approval process') ||
          undefined,
        disabled:
          (!invoice?.isDraft && doctype.isAccountingDocument) || undefined,
      })
    ) || [];

  const transformedData = useMemo(
    () =>
      documentsList?.appliableInvoiceSigningDocuments?.nodes.map(
        (documents) =>
          ({
            ...documents,
            available: documents.documentAppliedAmounts?.remainingTotal,
            isoCode: documents.currency?.isoCode,
            indexTransactionDate: documents?.indexTransactionDate
              ? dateFormat(dateConvertions(documents?.indexTransactionDate))
              : null,
          } as AttachableDataType)
      ),
    [documentsList]
  );

  const _onAttach = async () => {
    const entityDocuments = selected.map((item) => {
      const meta = documentMetaData.current.get(item.id);
      return {
        id: item.id,
        comment: meta?.comment,
        entityId: item.entityId,
        fileReference: item.fileReference,
        fileIndexInformation: item.fileIndexInformation,
        entityDocumentTypeId: item.entityDocumentTypeId,
        indexName: item.indexName,
        indexDescription: item.indexDescription,
        indexTransactionDate: item.indexTransactionDate,
        indexAmount: item.indexAmount,
        indexCurrencyId: item.indexCurrencyId,
        documentAppliedAmount: meta?.documentAppliedAmount,
        documentStatusExplanation: meta?.documentStatusExplanation,
        isAppliedAmountRetired: meta?.isAppliedAmountRetired,
        isAppliedAmountOverage: meta?.isAppliedAmountOverage,
        documentRetiredAmount: meta?.documentRetiredAmount,
      } as EntityDocumentInput;
    });

    const selectedEntities = selected.map((item) => {
      const meta = documentMetaData.current.get(item.id);
      const additionalData: DocumentMetaForm | undefined = meta
        ? meta
        : undefined;
      return {
        ...item,
        ...additionalData,
        id: item.id,
      } as AttachableDataType;
    });

    const tempPOInvoiceSchedulesArray = selectedEntities.map(
      (ele) => ele.poInvoiceSchedules
    );

    let pOInvoiceSchedulesArray: string[] = [];
    if (tempPOInvoiceSchedulesArray?.length) {
      pOInvoiceSchedulesArray =
        tempPOInvoiceSchedulesArray?.reduce(
          (accumulator, currentValue) => accumulator?.concat(currentValue!),
          []
        ) || [];
    }

    const { errors } = await attachDocuments({
      variables: {
        input: {
          invoiceId: invoice?.id!,
          entityDocuments: entityDocuments,
          poInvoiceSchedules: pOInvoiceSchedulesArray,
        } as ApplyInvoiceSigningDocumentInput,
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Document attached successfully', { appearance: 'success' });
      setOpen(false);
      onAttachSuccess();
    }
  };

  const loadMore = useCallback(
    async () =>
      await fetchMore({
        variables: {
          ...documentsVariables,
          after:
            documentsList?.appliableInvoiceSigningDocuments?.pageInfo.endCursor,
        },
      }),
    [fetchMore, documentsVariables, documentsList]
  );

  const onFiltersReload = useCallback(
    async (filter: EntityDocumentFilter | undefined) =>
      await refetch({ ...documentsVariables, filter }),
    [documentsVariables, refetch]
  );

  const reload = useCallback(
    async (sort?: SortOrder) =>
      await refetch({ after: null, orderBy: toOrderByVariable(sort) }),
    [refetch]
  );

  const onDocumentTypeReload = useCallback(
    async (documentTypeId: number | null) =>
      await refetch({ ...documentsVariables, documentTypeId }),
    [refetch, documentsVariables]
  );

  const onRenderItemColumn = useCallback(
    (item?: AttachableDataType, _index?: number, column?: IColumn) => {
      if (!item || !column) return undefined;
      const fieldContent = item[
        column.fieldName as keyof AttachableDataType
      ] as string | null;
      const disabled = !!(!invoice.isDraft && item._isAccountingDocument);
      switch (column.key) {
        case 'indexAmount':
        case 'available':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                className={clsx(
                  disabled && styles.disabledText,
                  styles.contentColumnAlignRight
                )}
                value={fieldContent}
              />
            </Stack>
          );
        case 'used':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <AmountTextView
                className={clsx(
                  item._isAccountingDocument ? styles.disabledText : '',
                  styles.contentColumnAlignRight
                )}
                value={item.documentAppliedAmounts?.usedTotal!}
              />
            </Stack>
          );
        case '_documentPoolName':
          return (
            <Stack
              horizontal
              className={styles.columnHeight}
              verticalAlign="center"
              tokens={{ childrenGap: 5 }}
            >
              {item._isDocumentDistributionAttachable && (
                <TooltipHost
                  content={'Document distributions will automatically be added'}
                >
                  <FontIcon
                    iconName="InfoSolid"
                    className={styles.distributionColor}
                  />
                </TooltipHost>
              )}
              <Text className={clsx(disabled && styles.disabledText)}>
                {fieldContent}
              </Text>
            </Stack>
          );
        case 'fileReference':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <DocumentDataCallout disabled={disabled} item={item} />
            </Stack>
          );
        case '_uploadDate':
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={clsx(disabled && styles.disabledText)}>
                {getGlobalDateFormat(item._uploadDate!)}
              </Text>
            </Stack>
          );
        case 'action':
            const viewDocumentVisible =
              item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentVisible}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );

        default:
          return (
            <Stack className={styles.columnHeight} verticalAlign="center">
              <Text className={clsx(disabled && styles.disabledText)}>
                {fieldContent}
              </Text>
            </Stack>
          );
      }
    },
    [invoice, styles]
  );

  const onRenderRow: IRenderFunction<IDetailsRowProps> = useCallback(
    (props, defaultRender) => {
      if (!props || !defaultRender) return null;

      const item = props.item as EntityDocumentsFields | null;

      // row is disabled
      if (item && !invoice.isDraft && item._isAccountingDocument)
        return (
          <TooltipHost
            content={PROHIBITED_MESSAGE}
            directionalHint={DirectionalHint.topCenter}
          >
            {defaultRender(props)}
          </TooltipHost>
        );

      const isSelected = item && selected.some((row) => row.id === item.id);
      // row is null, not selected, or not expandable
      if (
        !item ||
        !isSelected ||
        !invoice.isDraft ||
        !item._isAccountingDocument
      )
        return defaultRender(props);

      const onDataReceive = (data: DocumentMetaForm) => {
        documentMetaData.current.set(item.id, data);

        const metaValues = Array.from(documentMetaData.current.values());

        const checkApproveOverage = metaValues.some(
          (item) =>
            Number(item.remainingTotal!) <
              Number(item.documentAppliedAmount!) &&
            (!item.documentStatusExplanation || !item.isAppliedAmountOverage)
        );

        setDocumentAppliedAmount(getDocumentAppliedAmount());
        setCheckApproveOverage(checkApproveOverage);

        const inValidRetireAmount = metaValues.some(
          (item) =>
            item.isAppliedAmountRetired &&
            new Decimal(item?.documentRetiredAmount || 0).greaterThan(
              new Decimal(item?.remainingTotal || 0).minus(
                item?.documentAppliedAmount || 0
              )
            )
        );
        const nullRetireAmountValueError = metaValues.some(
          (item) =>
            item?.isAppliedAmountRetired && !Number(item?.documentRetiredAmount)
        );
        setCheckRetireAmount(inValidRetireAmount || nullRetireAmountValueError);
      };

      return (
        <Stack>
          {defaultRender!({
            ...props,
          })}
          <DocumentMetaForm onChange={onDataReceive} documentItem={item} />
        </Stack>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [invoice, selected, getDocumentAppliedAmount]
  );

  const appliedAmount = new Decimal(documentAppliedAmount).plus(
    invoice.entityAppliedAmounts?.appliedTotal || 0
  );

  const remainingTotal = new Decimal(invoice.controlTotalAmount || 0).minus(
    appliedAmount
  );

  const appliedAmountLogic =
    parseFloat(invoice.controlTotalAmount || '0.00') <
      appliedAmount.toNumber() && appliedAmount.toNumber() !== 0;

  const attachDisabled =
    selected.length === 0 || checkApproveOverage || checkRetireAmount;

  const { documentPosition, documentTypes, comment } = {
    ...invoice.entityDocumentsByEntityId.nodes?.[0],
  };
  const { isAccountingDocument } = { ...documentTypes };
  const documentComment =
    isAccountingDocument && documentPosition === 1 ? comment : null;
  const initialDocumentMetaData = {
    id: invoice?.entityDocumentsByEntityId?.nodes?.[0]?.id,
    indexName: vendorReference,
    comment: documentComment,
  };

  return (
    <>
      <AttachDocumentModal
        columns={columns}
        loading={documentsLoading}
        items={
          networkStatus === NetworkStatus.refetch ||
          networkStatus === NetworkStatus.setVariables
            ? undefined
            : transformedData
        }
        hasNextPage={
          documentsList?.appliableInvoiceSigningDocuments?.pageInfo.hasNextPage
        }
        modalWidth={1450}
        attachLoading={attachLoading}
        availableDocumentTypes={documentTypeOptions}
        setOpen={setOpen}
        onSortReload={reload}
        appliedAmountLogic={appliedAmountLogic}
        onFiltersReload={onFiltersReload}
        onLoadMore={loadMore}
        onRenderRow={onRenderRow}
        onDocumentTypeChange={onDocumentTypeReload}
        attachDisabled={attachDisabled}
        onSelectionChanged={onSelectionChanged}
        dropdownDisabled={selected.length > 0}
        onRenderItemColumn={onRenderItemColumn}
        onAttachDocuments={_onAttach}
        remainingTotal={remainingTotal.toNumber()}
        canSelectItem={canSelectItem}
        CalculateBoxProps={{
          currency: invoice.currency?.isoCode!,
          remainingTotal: remainingTotal.toNumber(),
          controlTotalAmount: new Decimal(
            invoice?.controlTotalAmount || 0
          ).toString(),
          appliedAmount: appliedAmount.toNumber(),
        }}
        initialDocumentMetaData={initialDocumentMetaData}
        isDraggable
      />
      <DocumentViewModal
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
